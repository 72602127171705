import { NavLink } from "react-router-dom";
import ItodoImage from "../../elements/itodo-img";
import { useState } from "react";
import { loadScript } from "../../../globals/constants";
import { useEffect } from "react";

function Header1() {

    const [isActive, setIsActive] = useState(false);

    function toggleNavClass() {
        setIsActive(!isActive)
    }

    useEffect(()=>{
        loadScript("js/mobilenav.js")
    })

    return (
        <>
            <header className={"header-style1 site-header  mobile-sider-drawer-menu " + (isActive ? "active" : "")}>
                <div className="top-bar">
                    <div className="container">
                        <div className="d-flex justify-content-between  align-content-center">
                            <div className="sx-topbar-left">
                                <ul className="e-p-bx">
                                    <li>hola@ovallesoft.com</li>
                                    <li><a href="tel:(1)245-45678">(+52) 777 388 7632</a></li>
                                </ul>
                            </div>
                            <div className="sx-topbar-right">
                                <div className="top-bar-nav">
                                    <ul>
                                        <li><NavLink to="/about-us">Terminos &amp; Condiciones</NavLink></li>
                                        <li><NavLink to="/faq">Politicas y privacidad</NavLink></li>
                                        <li><NavLink to="/contact-us">Contáctanos</NavLink></li>
                                    </ul>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sticky-header main-bar-wraper navbar-expand-lg">
                    <div className="main-bar">
                        <div className="container clearfix">
                            <div className="logo-header">
                                <div className="logo-header-inner logo-header-one">
                                    <NavLink to="/index">
                                        <ItodoImage src="images/Logo.png" alt=""   />
                                    </NavLink>
                                </div>
                            </div>
                            {/* NAV Toggle Button */}
                            <button id="mobile-side-drawer"
                                data-target=".header-nav"
                                data-toggle="collapse"
                                className="navbar-toggler collapsed"
                                onClick={toggleNavClass}
                            >
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar icon-bar-first" />
                                <span className="icon-bar icon-bar-two" />
                                <span className="icon-bar icon-bar-three" />
                            </button>
                            {/* EXTRA NAV */}
                            <div className="extra-nav">
                                <div className="extra-cell">
                                    <ul className="list-unstyled social-bx d-flex flex-wrap align-content-center">
                                        <li><a href="https://www.facebook.com/"><i className="feather-facebook" /> </a></li>
                                        <li><a href="https://twitter.com/"><i className="feather-twitter" /> </a></li>
                                        <li><a href="https://www.linkedin.com/"><i className="feather-linkedin" /> </a></li>
                                        <li><a href="https://www.instagram.com/"><i className="feather-instagram" /> </a></li>
                                    </ul>
                                </div>
                            </div>
                            {/* EXTRA Nav */}
                            {/* MAIN NAVIGATION */}
                            <div className="header-nav navbar-collapse collapse d-flex justify-content-center collapse ">
                                <ul className=" nav navbar-nav ">
                                    <li className="active">
                                        <NavLink to="/index">Inicio</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/about-us">Nosotros</NavLink>
                                        <ul className="sub-menu">
                                            <li><NavLink to="/about-us">Acerca de nosotros</NavLink></li>
                                            <li><NavLink to="/gallery">Nuestros proyectos</NavLink></li>
                                            <li><NavLink to="/testimonials">Testimonios</NavLink></li>
                                            <li><NavLink to="/why-choose-us">Por que elegirnos</NavLink></li>
                                            <li><NavLink to="/team">Conoce al equipo</NavLink></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <NavLink to="/mission">Soluciones</NavLink>
                                        <ul className="sub-menu">
                                            <li><NavLink to="/mission">Mision &amp; Vision</NavLink></li>
                                            <li><NavLink to="/services">Nuestros servicios</NavLink></li>
                                            <li><NavLink to="/portfolio">Portfolio</NavLink></li>
                                            <li><NavLink to="/portfolio2">Portfolio-2</NavLink></li>
                                            <li><NavLink to="/portfolio/detail">Portfolio Detail</NavLink></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <NavLink to="/blogs">Noticias</NavLink>
                                        <ul className="sub-menu">
                                            <li><NavLink to="/blogs">Blog</NavLink></li>
                                            <li><NavLink to="/blogs/list">Blog Grid Sidebar </NavLink></li>
                                            <li><NavLink to="/blogs/detail-with-sidebar">Blog Detail Sidebar</NavLink></li>
                                            <li><NavLink to="/blogs/detail">Blog Detail</NavLink></li>
                                        </ul>
                                    </li>
                                <li>
                                    <NavLink to="/blogs">Investigación</NavLink>
                                    <ul className="sub-menu">
                                        <li><NavLink to="/blogs">Blog</NavLink></li>
                                        <li><NavLink to="/blogs/list">Blog Grid Sidebar </NavLink></li>
                                        <li><NavLink to="/blogs/detail-with-sidebar">Blog Detail Sidebar</NavLink></li>
                                        <li><NavLink to="/blogs/detail">Blog Detail</NavLink></li>
                                    </ul>
                                </li>
                                    <li><NavLink to="/contact-us">Contáctanos</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header >

        </>
    )
}

export default Header1;