import { NavLink } from "react-router-dom";

function SectionServices1() {
    return (
        <>
            <div className="section-full p-t110 p-b80 sx-our-services-outer sx-bg-light">
                <div className="container">
                    {/* TITLE START */}
                    <div className="section-head center">
                        <div className="sx-head-s-title">Nuestros servicios</div>
                        <div className="sx-head-l-title">
                            <h2 className="sx-title">¡Conocimiento Sofisticado Para Un Servicio Legal De Rápida Aparición De Nuestra Solución De TI!</h2>
                        </div>
                    </div>
                    {/* TITLE END */}
                    <div className="section-content">
                        <div className="sx-our-services-bx">
                            <div className="row">
                                {/* Block one */}
                                <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                                    <div className="sx-service-bx-1">
                                        <div className="sx-icon-box-wraper">
                                            <div className="sx-service-bx-icon scale-in-center">
                                                <span className="sx-text-primary"><i className="flaticon-security" /></span>
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="sx-tilte">Seguridad de la Informatica</h4>
                                                <p style={{ textAlign: 'justify' }}>
                                                  En OvalleSoft protege tu información con nuestros servicios de seguridad informática avanzada. Implementamos firewalls, cifrado y detección de intrusiones para garantizar la integridad y confidencialidad de tus datos. Evita ciberataques y asegura tu tranquilidad con nuestra protección experta.
                                                </p>
                                            
                                            
                                                <div className="sx-center-btn">
                                                    <NavLink to="/services/detail" className="site-button-circle">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Block Two */}
                                <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                                    <div className="sx-service-bx-1">
                                        <div className="sx-icon-box-wraper">
                                            <div className="sx-service-bx-icon scale-in-center">
                                                <span className="sx-text-primary"><i className="flaticon-coding" /></span>
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="sx-tilte">Big Data</h4>
                                                <p style={{ textAlign: 'justify' }}>
                                                    En OvalleSoft transformamos tus datos en conocimiento valioso con nuestros servicios de Big Data. Analizamos grandes volúmenes de información para obtener insights que impulsen tu negocio. Implementamos técnicas avanzadas de análisis de datos y visualización para ayudarte a tomar decisiones informadas y estratégicas.
                                                </p>

                                                <div className="sx-center-btn">
                                                    <NavLink to="/services/detail" className="site-button-circle active">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Block Three */}
                                <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                                    <div className="sx-service-bx-1">
                                        <div className="sx-icon-box-wraper">
                                            <div className="sx-service-bx-icon scale-in-center">
                                                <span className="sx-text-primary"><i className="flaticon-smart-contracts" /></span>
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="sx-tilte">Inteligencia Artificial</h4>
                                                <p style={{ textAlign: 'justify' }}>
                                                    En OvalleSoft impulsamos tu negocio con nuestros servicios de Inteligencia Artificial. Desarrollamos soluciones personalizadas que automatizan procesos, mejoran la eficiencia y optimizan la toma de decisiones. Implementamos modelos avanzados de aprendizaje automático para ofrecerte insights precisos y valiosos.
                                                </p>

                                                <div className="sx-center-btn">
                                                    <NavLink to="/services/detail" className="site-button-circle">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionServices1;